import React, { useState } from "react";
import "./Style.css";
import { Account } from "./views/account/Account";
import { Family } from "./views/family/Family";
import { Payment } from "./views/payment/Payment";
import { IHomeProps } from "./IHomeProps";
import { Button, Form, Modal } from "react-bootstrap";

export const Home: React.FC<IHomeProps> = (props: IHomeProps): JSX.Element => {
  const [accountView, setAccountView] = useState(true);
  const [familyView, setFamilyView] = useState(false);
  const [paymentView, setPaymentView] = useState(false);

  const [isMobile, setIsMobile] = useState("sb-nav-fixed");

  function clearViews() {
    setAccountView(false);
    setFamilyView(false);
    setPaymentView(false);
  }

  function showAccounts() {
    clearViews();
    setAccountView(true);
  }
  function showFamily() {
    clearViews();
    setFamilyView(true);
  }
  function showPayments() {
    clearViews();
    setPaymentView(true);
  }
  function logout() {
    props.logout();
  }

  function showHideMenu() {
    console.log("i get here");

    if (isMobile === "sb-nav-fixed sb-sidenav-toggled") {
      setIsMobile("sb-nav-fixed");
    } else {
      setIsMobile("sb-nav-fixed sb-sidenav-toggled");
    }
  }

  const home1 = (): JSX.Element => {
    return (
      <body>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
          <div className="container">
            <Button
              variant="link"
              className="navbar-brand"
              onClick={showAccounts}
            >
              PBS
            </Button>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarResponsive"
              aria-controls="navbarResponsive"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarResponsive">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item active">
                  <Button
                    variant="link"
                    className="nav-link"
                    onClick={showAccounts}
                    data-toggle="collapse"
                    data-target="#navbarResponsive"
                    aria-controls="navbarResponsive"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    Account
                  </Button>
                </li>
                <li className="nav-item active">
                  <Button
                    variant="link"
                    className="nav-link"
                    onClick={showFamily}
                    data-toggle="collapse"
                    data-target="#navbarResponsive"
                    aria-controls="navbarResponsive"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    Family
                  </Button>
                </li>
                <li className="nav-item active">
                  <Button
                    variant="link"
                    className="nav-link"
                    onClick={showPayments}
                    data-toggle="collapse"
                    data-target="#navbarResponsive"
                    aria-controls="navbarResponsive"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    Payments
                  </Button>
                </li>
                <li className="nav-item active">
                  <Button
                    variant="link"
                    className="nav-link"
                    onClick={logout}
                    data-toggle="collapse"
                    data-target="#navbarResponsive"
                    aria-controls="navbarResponsive"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    Logout
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <header className="bg-primary py-5 mb-5">
          <div className="container h-100">
            <div className="row h-100 align-items-center">
              <div className="col-lg-12">
                <h1 className="display-4 text-white mt-5 mb-2">PBS</h1>
                <p className="lead mb-5 text-white-50">
                  Pukhtoon Burial Society
                </p>
              </div>
            </div>
          </div>
        </header>
        <div className="container">
          {accountView ? <Account /> : <></>}
          {familyView ? <Family /> : <></>}
          {paymentView ? <Payment /> : <></>}
        </div>

        <footer className="py-5 bg-dark">
          <div className="container">
            <p className="m-0 text-center text-white">
              Copyright &copy; admin@pbs-bradford.com
            </p>
          </div>
        </footer>
      </body>
    );
  };

  return <div>{home1()}</div>;
};
