/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect } from "react";
import { Alert, Card } from "react-bootstrap";
import { IMember } from "../../../../types/IMember";
import ApplicationContext from "../../../../context/ApplicationContext";
import { getfamilyinformation } from "../../../../services/Service";
import { IFamilyInformation } from "../../../../types/IFamilyInformation";
import { IFamily } from "../../../../types/IFamily";
import loadingImage from "../../../../images/loading.gif";

export const Account: React.FC = (): JSX.Element => {
  const context = useContext(ApplicationContext);

  const defaultFamily: IFamily = {
    familyId: 0,
    guid: "",
    lastUpdateDateTime: new Date(),
    systemOrganisationId: 0,
    name: "",
    houseNumber: "",
    addressLine: "",
    postcode: "",
    mobile: "",
    emailAddress: "",
    username: "",
    reference: "",
    approved: false,
    ignore: false,
  };

  const [family, setFamily] = React.useState(defaultFamily);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const user = context.AuthenticatedUser.getUser();

  async function LoadData() {
    setLoading(true);
    const response = await getfamilyinformation(
      user.sessionGuid,
      user.familyGuid
    );

    if (!response) {
      setError(true);
      setLoading(false);
      return;
    }

    //response.message

    context.FamilyData.setFamily(response);
    setLoading(false);
  }

  function viewUpdate() {
    const d1 = context.FamilyData;

    if (d1 === undefined) {
      return;
    }

    const d2 = context.FamilyData.getFamily();

    if (d2 === undefined) {
      return;
    }

    const d3 = context.FamilyData.getFamily().familyComplete;

    if (d3 === undefined) {
      return;
    }

    const data = context.FamilyData.getFamily().familyComplete.family;

    if (data === undefined) {
      return;
    }

    setFamily(data);
  }
  useEffect(() => {
    viewUpdate();
  }, [loading]);

  useEffect(() => {
    LoadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function awaitingApproval() {
    return (
      <Alert variant="danger">
        <Alert.Heading>Account is awaiting approval</Alert.Heading>
        <p>
          Salaams, we are doing our very best to approve all account. please
          bear with us
        </p>
        <hr />
        <p className="mb-0">just remember we are all working as a volunteer</p>
      </Alert>
    );
  }

  const loadingData = (): JSX.Element => {
    return (
      <div className="loading-center">
        <img src={loadingImage} alt="Loading" />
      </div>
    );
  };

  const errorView = (): JSX.Element => {
    return (
      <div className="loading-center" style={{ padding: 100 }}>
        Error Communicating with server!
      </div>
    );
  };

  function registeredAccount() {
    return (
      <Card>
        <Card.Header>Account approved</Card.Header>
        <Card.Body>
          <Card.Title>{family.name}</Card.Title>
          <Card.Text>
            This account has been approved. Communication will be sent to this
            number in the future
          </Card.Text>
          <div className="form-group row">
            <label className="col-sm-2 col-form-label">Name</label>
            <div className="col-sm-10 col-form-label">
              <label>{family.name}</label>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-2 col-form-label">House number</label>
            <div className="col-sm-10 col-form-label">
              <label>{family.houseNumber}</label>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-2 col-form-label">Address</label>
            <div className="col-sm-10 col-form-label">
              <label>{family.addressLine}</label>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-2 col-form-label">Postcode</label>
            <div className="col-sm-10 col-form-label">
              <label>{family.postcode}</label>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-2 col-form-label">Mobile</label>
            <div className="col-sm-10 col-form-label">
              <label>{family.username}</label>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-2 col-form-label">Email</label>
            <div className="col-sm-10 col-form-label">
              <label>{family.emailAddress}</label>
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  }

  const account = (): JSX.Element => {
    return (
      <div>
        <h2>Account</h2>
        <hr />
        <div>
          <div>{registeredAccount()}</div>
        </div>
        <br />
      </div>
    );
  };

  return (
    <div>
      <div>{!loading && !error ? account() : <></>}</div>
      <div>{loading ? loadingData() : <></>}</div>
      <div>{error ? errorView() : <></>}</div>
    </div>
  );
};
